const notifications = {
  new_order_sound_url:
    "https://cdn.healistic.net/uploads/sounds/pharmacy_new_order.mp3",
  new_order_first_time_sound_url:
    "https://cdn.healistic.net/uploads/sounds/pharmacy_new_order_3_bips.mp3",
  new_order_loop_sound_url:
    "https://cdn.healistic.net/uploads/sounds/pharmacy_new_order_1_bip.mp3",
};

export { notifications };
